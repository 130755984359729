
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import VLanguageField from '@/components/shared/form/VLanguageField.vue';
import { InputType } from '@/enums/InputType';
import { Campaign as CampaignModel } from '@/interfaces/models/Campaign';
import { Venue } from '@/interfaces/models/Venue';
import { User } from '@/interfaces/models/User';
import StackedForm from '../../mixins/StackedForm';
import CustomerGroup from '@/mixins/CustomerGroup';
import { venueFilter } from '@/util/helper';

@Component({
  components: { VFormBuilder, VLanguageField },
})
export default class CampaignForm extends mixins(StackedForm, CustomerGroup) {
  @Prop({ type: Boolean, default: false }) public editing!: boolean;
  @Prop({ type: Boolean, default: false }) public editable!: boolean;
  @Prop({ type: Array }) public venues!: Venue[];
  @Prop({ type: Object }) public user!: User;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    campaignObserver: InstanceType<typeof ValidationObserver>;
  };

  public selectedCustomerGroup: string = '';
  public selectedVenues: string[] = [];

  get accessibleCustomerGroups() {
    if (this.$isAdmin() || this.editing) {
      return this.customerGroups;
    }

    return this.user.customerGroup ? [this.user.customerGroup] : [];
  }

  get items() {
    return [
      ...(this.accessibleCustomerGroups?.length
        ? [
            {
              name: 'customerGroup',
              type: InputType.Autocomplete,
              label: 'campaign.form.customerGroup',
              items: this.accessibleCustomerGroups,
              multiple: false,
              rules: !this.selectedVenues.length ? 'required' : '',
              disabled: !this.editable,
              change: this.onCustomerGroupSelect,
            },
          ]
        : []),
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'campaign.form.venues',
        itemValue: '_id',
        itemText: 'name',
        multiple: true,
        rules: !this.selectedCustomerGroup ? 'required' : '',
        items: this.venues,
        filter: venueFilter,
        disabled: !this.editable,
        change: this.onVenuesSelect,
      },
      {
        name: 'name',
        type: InputType.Text,
        label: 'campaign.form.name',
        rules: 'required',
        disabled: !this.editable,
      },
      {
        name: 'content',
        type: InputType.Text,
        label: 'campaign.form.content',
        rules: 'required',
        disabled: !this.editable,
      },
      {
        name: 'subject',
        type: InputType.Text,
        label: 'campaign.form.subject',
        rules: 'required',
        disabled: !this.editable,
      },
      {
        name: 'clickAction',
        type: InputType.Text,
        label: 'campaign.form.clickAction',
        rules: 'url_simple',
        disabled: !this.editable,
      },
      {
        name: 'url',
        type: InputType.Text,
        label: 'campaign.form.url',
        rules: 'url_simple',
        disabled: !this.editable,
      },
      {
        name: 'scheduledAt',
        type: InputType.DateTime,
        label: 'campaign.form.scheduledAt',
        allowedMinutes: (m: number) => m % 15 === 0,
        rules: 'required',
        disabled: !this.editable,
      },
    ];
  }

  public mounted() {
    if (!this.editing) {
      return;
    }

    const initVals = this.initialValues as CampaignModel;

    if (initVals?.customerGroup) {
      this.selectedCustomerGroup = initVals.customerGroup;
    }

    if (initVals?.venues?.length) {
      this.selectedVenues = initVals.venues;
    }
  }

  public onCustomerGroupSelect() {
    const selectedCustomerGroup = this.$refs.form.getData()?.customerGroup;

    if (selectedCustomerGroup) {
      this.selectedCustomerGroup = selectedCustomerGroup;
      this.$refs.form.setValue('venues', []);
      this.selectedVenues = [];
    }
  }

  public onVenuesSelect() {
    const selectedVenues = this.$refs.form.getData()?.venues;

    if (selectedVenues?.length) {
      this.selectedVenues = selectedVenues;
      this.$refs.form.setValue('customerGroup', '');
      this.selectedCustomerGroup = '';
    }
  }

  public getData() {
    const data = {
      ...this.$refs.form.getData(),
    };

    if (!data.customerGroup && data.venues?.length) {
      delete data.customerGroup;
    }

    if (!data.venues?.length && data.customerGroup) {
      delete data.venues;
    }

    return data;
  }
}
